import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserObj } from '../../../../../core/models/user';
import { AuthService } from '../../auth/auth.service';
import { LoginFormComponent } from '../components/login-form/login-form.component';
import { UserPermissions } from '../../../../../core/dto/userDoc';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from '@environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public form = LoginFormComponent.formModel();
  public user: UserObj;
  public loading = false;
  public permissions: UserPermissions = {
    checklists: false,
    users: false,
    groups: false,
    reports: false,
    designateInstructors: false,
    testSupervisor: false,
    testAdminView: false,
    testAdminEdit: false,
    safetyAlertsView: false,
    safetyAlertsEdit: false,
    daModuleEdit: false,
    daModuleView: false,
    daModuleOrder: false,
    daModuleViewOrder: false,
    utModuleEdit: false,
    utModuleOrder: false,
  };
  public logo = `../../../assets/images/${environment.envName.includes('peopletasker') ? 'peopletasker' : 'railtasker'}-logo.png`;

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private router: Router,
    private snackbar: MatSnackBar,
    private userService: UserService,
  ) { }

  ngOnInit() {
    const isIE = /msie\s|trident\//i.test(window.navigator.userAgent);
    if (isIE) {
      this.snackbar.open(`This site may not work properly in Internet Explorer.
      Please use a modern browser such as Chrome, Firefox or Edge for the best possible experience.`,
        '', { duration: 10000 });
    }
  }

  async signIn() {
    this.loading = true;
    const { value } = this.form;
    let email = '';
    if (value.username.includes('@')) {
      email = value.username;
    } else {
      email = this.userService.appendEmailToUsername(value.username);
    }
    await this.authService.signIn(email, value.password)
        .then((res) => {
          this.router.navigate(['/']);
        })
        .catch(error => {
          let message = error.message;
          if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
            message = 'Invalid login. User name or password is incorrect.';
          }
          this.snackbar.open(message, '', { duration: 4000 });
          this.loading = false;
        });
  }

  async enterpriseSignIn() {
    this.loading = true;

    this.router.navigate(['/enterprise/login']);
  }
}
