<div class="container">
  <div class="top">
    <div class="logo">
      {{ envName }} v{{ version }}
      <app-logo></app-logo>
    </div>
    <hr />
    <div class="tenant">
      {{ tenantName }}
    </div>
    <hr />
    <div class="content flex-column justify-start">
      <div *ngIf="permissions.users">
        <a
          class="title first"
          routerLinkActive="active"
          routerLink="/dashboard/users"
        >
          Users
        </a>
      </div>
      <div *ngIf="permissions.groups">
        <a
          class="title"
          routerLinkActive="active"
          routerLink="/dashboard/groups"
        >
          Groups
        </a>
      </div>
      <div
        *ngIf="
          permissions.checklists ||
          permissions.designateInstructors ||
          permissions.reports ||
          permissions.users
        "
      >
        <a
          class="title"
          routerLinkActive="active"
          routerLink="/dashboard/checklists"
        >
          Checklists
        </a>
      </div>
      <div
        *ngIf="
          tenant?.operationsModule &&
          (permissions.testAdminView || permissions.testAdminEdit)
        "
      >
        <a class="title"
          routerLinkActive="active"
          routerLink="/dashboard/operations-module"
        > {{ tenant?.id === 'ns' ? 'PSE' : 'Operations Tests' }}
        </a>
      </div>
      <div *ngIf="canCreateTenant">
        <a
          class="title"
          routerLinkActive="active"
          routerLink="/dashboard/tenants"
        >
          Tenants
        </a>
      </div>
      <div
        *ngIf="
          tenant?.safetyAlertsModule &&
          (permissions.safetyAlertsView || permissions.safetyAlertsEdit || (tenant.id === 'ns' && permissions.testSupervisor))
        "
      >
        <a
          class="title"
          routerLinkActive="active"
          routerLink="/dashboard/safety-alerts/alerts"
          >Safety Engagements</a
        >
      </div>
      <div
        *ngIf="
          !showLimitedView &&
          (tenant?.daModule && (permissions.daModuleView || permissions.daModuleEdit)) || (tenant?.unscheduledTestsModule && (permissions.utModuleEdit || permissions.utModuleOrder))
        "
      >
        <a
          class="title"
          routerLinkActive="active"
          routerLink="/dashboard/drug-and-alcohol"
          >Drug & Alcohol Testing</a
        >
      </div>
      <div
        *ngIf="
          tenant?.qualificationsModule &&
          (!!permissions.qualificationsModule?.view ||
            !!permissions.qualificationsModule?.edit)
        "
      >
        <a
          class="title"
          routerLinkActive="active"
          routerLink="/dashboard/qualifications"
          >Qualifications</a
        >
      </div>
      <div
        *ngIf="
          tenant?.onboardingModule &&
          (!!permissions.onboardingModule?.view ||
            !!permissions.onboardingModule?.edit)
        "
      >
        <a
          class="title"
          routerLinkActive="active"
          routerLink="/dashboard/onboarding"
          >Onboarding</a
        >
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="logout">
      <div>
        <a
          class="title"
          routerLinkActive="active"
          routerLink="/dashboard/profile/change-password"
        >
          Profile
        </a>
      </div>
      <button mat-button class="white-text title" (click)="logout()">
        Log Out
      </button>
    </div>
    <div class="user-name">
      <small>{{ userName }}</small>
    </div>
  </div>
</div>
