import { DateTime } from 'luxon';
import { UserObj } from "./user";
import {
  TestSubdivisionDocument,
  TestTerminalDocument,
  TestNameDocument,
  TestEventDocument,
  TestTypeDocument,
  TestCategoryDocument,
  TestEventObject} from "../dto";
import { convertTimestamp } from "../utils/dates/convertTimestamp";
import { TestSubtype } from './test-subtype';
import { TestDistrict } from './test-district';
export class TestEvent implements TestEventDocument {
  archived: boolean;
  createdBy: string; // AccountHolder Id
  createdOn: Date; // Date the test was entered
  id: string;
  lastUpdatedOn: Date;
  updatedBy: string;  // AccountHolder Id
  eventId: string // the id for the event to bulk creation
  type: TestTypeDocument;
  subtypes: TestSubtype[];
  category: TestCategoryDocument;
  name: TestNameDocument;
  supervisors: UserObj[];
  supervisorIds: string[]; // need for array contains query
  employee: UserObj;
  locationDetails: {
    subdivision: TestSubdivisionDocument,
    district?: TestDistrict,
    terminal?: TestTerminalDocument,
    startMilepost?: string,
    endMilepost?: string,
  }
  jobDuty?: string = null;
  trainOrJob: string;
  timeOfDay: 'Daylight' | 'Dark';
  result: 'Pass' | 'Fail' | null;
  comments?: string;
  date: Date; // Date the test was performed
  startTime?: Date = null;
  testGroup?: string = null;
  trainType?: string = null;
  followUpDetails?: string;
  constructor(test?: TestEvent | TestEventDocument | TestEventObject) {
    if (!test) {
      this.assignBlankValues();
    } else {
      this.assignValues(test);
    }
  }

  get valid() {
    const values = Object.values(this).filter(value => value === undefined);
    return values.length <= 0;

  }

  /**
   * Returns the time the test occurred
   */
  get time() {
    const d = DateTime.fromJSDate(this.date);
    return d.toFormat('h:mm a');
  }

  /**
   * Returns true if test occurred on a weekday
   */
  get weekday() {
    const dayOfWeek = DateTime.fromJSDate(this.date).weekday;
    return dayOfWeek > 0 && dayOfWeek < 6;
  }

  /**
   * Returns true if more than one supervisor
   * conducted the test
   */
  get teamTest(): boolean {
    return this.supervisors.length > 1;
  }

  // Represents whether the test event is editable in the mobile app
  get canEdit(): boolean {
    const daysApart = DateTime.fromJSDate(this.createdOn).diffNow('days').days;
    return daysApart > -10;
  }

  // Represents whether the test event is editable in the admin app
  get adminAppEditable() {
    const daysApart = DateTime.fromJSDate(this.createdOn).diffNow('days').days;
    return daysApart > -90;
  }

  pass() {
    this.result = 'Pass';
  }

  fail(comments: string) {
    this.result = 'Fail';
    this.comments = comments;
  }

  // Used to create a blank test event object
  private assignBlankValues() {
    this.archived = undefined;
    this.createdBy = undefined;
    this.createdOn = undefined;
    this.id = undefined;
    this.lastUpdatedOn = undefined;
    this.updatedBy = undefined;
    this.type = undefined;
    this.category = undefined;
    this.name = undefined;
    this.supervisors = undefined;
    this.supervisorIds = undefined;
    this.employee = undefined;
    this.eventId = undefined;
    this.locationDetails = {
      subdivision: undefined,
      district: undefined,
      terminal: undefined,
      startMilepost: undefined,
      endMilepost: undefined,
    };
    this.trainOrJob = undefined;
    this.timeOfDay = undefined;
    this.result = undefined;
    this.startTime = undefined;
    this.date = new Date();
    this.jobDuty = null;
    this.testGroup = null;
    this.trainType = null;
    this.subtypes = null;
  }


  private assignValues(test: Partial<TestEvent | TestEventDocument | TestEventObject | any>) {
    this.archived = test.archived;
    this.createdBy = test.createdBy;
    this.createdOn = test.createdOn ? convertTimestamp(test.createdOn) : undefined;
    this.id = test.id;
    this.lastUpdatedOn = test.lastUpdatedOn ? convertTimestamp(test.lastUpdatedOn) : undefined;
    this.updatedBy = test.updatedBy;
    this.type = test.type;
    this.category = test.category;
    this.name = test.name;
    this.supervisors = test.supervisors.map((s: any) => new UserObj(s));
    this.supervisorIds = test.supervisorIds;
    this.employee = new UserObj(test.employee);
    this.eventId = test.eventId;
    this.locationDetails = {
      subdivision: test.locationDetails && test.locationDetails.subdivision || test.subdivision,
      district: test.locationDetails && test.locationDetails.district || test.district || null,
      terminal: test.locationDetails && test.locationDetails.terminal || test.terminal || null,
      startMilepost: test.locationDetails && test.locationDetails.startMilepost ? test.locationDetails.startMilepost : test.startMilepost || null,
      endMilepost: test.locationDetails && test.locationDetails.endMilepost ? test.locationDetails.endMilepost : test.endMilepost || null,
    };
    this.trainOrJob = test.trainOrJob;
    this.timeOfDay = test.timeOfDay;
    this.jobDuty = test.jobDuty ? test.jobDuty : null;
    this.testGroup = test.testGroup ? test.testGroup : null;
    this.subtypes = test.subtypes ? test.subtypes : null;
    this.result = test.result || null;
    this.date = test.date ? convertTimestamp(test.date) : undefined;
    if (test.comments) { this.comments = test.comments; }
    if (test.startTime) { this.startTime = test.startTime ? convertTimestamp(test.startTime): undefined; }
    if (test.trainType) {this.trainType = test.trainType;}
    if (test.followUpDetails) { this.followUpDetails = test.followUpDetails; }

  }

  firestoreObject() {
    const employee = { ...this.employee }
    const supervisors = this.supervisors.map(s => ({ ...s}));
    const subdivision = { ...this.locationDetails.subdivision };
    const terminal = this.locationDetails.terminal ? { ...this.locationDetails.terminal } : null;
    const district = this.locationDetails.district ? { ...this.locationDetails.district } : null;

    return {
      ...this,
      employee,
      supervisors,
      locationDetails: {
        ...this.locationDetails,
        subdivision,
        district,
        terminal,
      }
    };
  }

}