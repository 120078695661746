import { UserObj } from '../models';
import { UserRole } from '../../core/enums/userRole';
import { UserPermissions } from './userDoc';
import { SaUserAssignmentDto } from './sa-user-assignment-dto';

// Represents a row in the postgres user table
export interface UserRow {
  id: string;
  firebase_id: string;
  archived: boolean;
  created_by: string; // AccountHolder Id
  created_on: Date;
  last_updated_on: Date;
  updated_by: string; // AccountHolder Id
  active: boolean;
  agreement: boolean;
  auth_id: string;
  email: string;
  employee_id: string;
  first_name: string;
  firebase_group_id: string;
  group_name: string;
  group_permissions: Array<string>;
  keywords: Array<string>;
  last_name: string;
  location: string;
  permissions: UserPermissions;
  phone: string;
  firebase_tenant_id: string;
  title: string;
  username: string;
  user_role: UserRole;
  tenantId: number;
  groupId: number;
  myTeam?: UserRow[];
  saAssignmentStatus?: string;
  saUserAssignments?: SaUserAssignmentDto;
  cost_center: string;
  division: string;
  zone: string;
  pay_id: string;
  budget_group: string;
}

export interface QPAndDIUserRecord extends UserRow {
  expiration_date: string;
  user_type: 'QP' | 'DI';
}

export function createUserObjFromUserRowRecord(userData: UserRow): UserObj {
  return new UserObj({
    ...userData,
    agreement: userData.agreement,
    authId: userData.auth_id,
    id: userData.firebase_id,
    createdBy: userData.created_by,
    createdOn: userData.created_on,
    employeeId: userData.employee_id,
    firstName: userData.first_name,
    groupId: userData.firebase_group_id,
    groupName: userData.group_name,
    lastName: userData.last_name,
    lastUpdatedOn: userData.last_updated_on,
    tenantId: userData.firebase_tenant_id,
    groupPermissions: userData.group_permissions,
    updatedBy: userData.updated_by,
    userRole: userData.user_role,
    sqlTenantId: userData.tenantId,
    sqlGroupId: userData.groupId,
    sqlId: userData.id,
    costCenter: userData.cost_center,
    division: userData.division,
    zone: userData.zone,
    payId: userData.pay_id,
    budgetGroup: userData.budget_group
  });
}
